h3, ul {
  margin: 0;
}

h3 {
  font-size: 1.4em;
}

#listas ul {
  width: 100%;
  height: 100%;
}

.segundaLista{
  min-height: 100%;
}


@media (max-width: 997px){
  #listas {
    flex-direction: column;
  }

  h3 {
    font-size: 1.2em;
  }
}
