#mapa {
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

#mapa > div {
    border-radius: 25px;
    box-shadow: 0px 0px 100px -50px rgba(0,0,0,0.75);
    width: 100%;
}

@media (max-width: 992px) {
    #mapa {
        width: 100%;
        height: 300px;
        padding: 0 10px;
        margin-top: 60px;
    }
}
