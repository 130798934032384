:root{
    --secundario: #a83468;
  }

a{
    text-decoration: none!important;
    color: var(--secundario)!important;
}

p{
    margin: 0!important;
    transition: 0.3s;
    cursor: pointer;
}

#barraNavegacion{
    height: 5em;
    width: 100%;
    box-shadow: 0px 0px 30px 3px rgba(0, 0, 0, 0.146);
    background-color: white;
    position: fixed;
    z-index: 1;
}

#barraNav{
    height: 5em;
}

#logo{
    height: 5em;
    transition: 0.3s;
    padding-left: 0em!important;
}

    #logo:hover{
        filter: brightness(1.2);
    }

.layoutList {
    font-weight: bold;
    margin:0 1em;
    transition: 0.3s;
    padding: 10px 15px;
}

.layoutList:hover{
    background-color: rgba(191, 191, 191, 0.189);
}

.burger-btn{
    background-color: transparent;
    color: var(--secundario);
    box-shadow: none;
    font-size: 2em;
    padding-right: 1.5em;
    transition: 00.3s;

    display: none;
}



#outletLayout{
    min-height: calc(100vh - 1em);
    padding-top: 5em;
    width: 100%;
}

@media (max-width: 992px){
    #barraNavegacion{
        height: 4em;
    }

    .barraNav{
        height: 4em;
    }

    #outletLayout{
        min-height: calc(100vh - 12em);
        padding-top: 4em;
    }

    .nav-menu{
        background-color: var(--secundario);
        position: fixed;
        top: 4em;
        left: 100%;
        width: 100%;
        height: calc(100vh - 4em);
        justify-content: center;
        transition: 0.3s ease-in-out;
        overflow-y: auto;
    }

    .nav-menu_active{
        left: 0;
    }

    .nav-menu ul{
        flex-direction: column;
        height: 100%;
        justify-content: space-evenly!important;
    }

    .layoutList{
        padding: 0.5em 0;
        font-size: 1.3em;
        font-weight: bolder;
        transition: 0s;
        color: white!important;
        transition: 200ms all;
    }
            .layoutList:hover{
                background-color: transparent;
                color: #c7698a!important;
            }

    .presupuesto{
        background-color: white;
        color: var(--secundario);
    }

    #logo{
        padding-left: 0.8em;
        height: 4em;
    }

    .burger-btn{
        display: block;
    }

    .burger-rotate{
        transition: 0.3s ease-out;
    }

    .burger-rotate_active{
        transform: rotate(180deg);
    }

}

@media (max-width: 576px){
    .burger-btn{
        padding-right: 0.5em;
        font-size: 1.7em;
    }
}
