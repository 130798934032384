#dondeEstamosBox {
    min-height: 100%;
    min-width: 300px;
}

#divMapa {
    width: 50%;
}

@media (max-width: 992px) {
    #dondeEstamosBox {
        flex-direction: column;
    }

    #divMapa {
        width: 100%;
    }
}
