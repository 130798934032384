:root{
  --secundario: #952a5a;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  min-height: 100vh;
  min-width: 100%;
  overflow-x: hidden;
}

/**/
::-webkit-scrollbar {
  width: 17px;
}
::-webkit-scrollbar:hover {
  width: 20px;
}
::-webkit-scrollbar-track{
  background-color: rgb(223, 223, 223);
}

::-webkit-scrollbar-thumb {
  background: var(--secundario);
  border-radius: 100px;
  box-shadow: inset 0 0 10px #5e0e31;
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active  {
  background: #5e0e31;
}
/**/
